@import '~@andes/technical-error/index';
@import '~@andes/badge/index';
@import '~@andes/snackbar/index';
@import '~@andes/typography/index';
@import '~@andes/empty-state/index';
@import '~@andes/button/index';
@import '~@andes/thumbnail/index';

@import '../styles/variables';

body {
  margin: 0;
}

main.shops-error-page {
  height: 100vh;
}

.marketplace-error-page {
  .marketplace-error-page__empty-state {
    height: 100vh;
  }
}
